@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
body {
    margin: 0;
    padding: 0;
    background-color: #eaeaeacc;
    font-family: 'Poppins', sans-serif;
}
:root{
    --primary-color:rgb(219, 219, 219);
    --primary-light-color:rgba(253, 253, 253, 0.815);
    --secondary-color:rgb(0, 255, 242);
    --light-color:#fff;
    --dark-color:#252525;
    --gery-color:#9e9e9e;
    --white-transparent: rgba(255, 255, 255, 0.3);
    --font-family: 'Poppins', sans-serif;
    --purple : #0b479d;
    --purple-secondary-color: #AE92FF;
    --primary-linear-color : linear-gradient(90deg, #8056F7 0%, #AE92FF 100%);
    --paid-color: #83de55;
    --unpaid-color: #de5f55;
    --total-color: #6883ef;


}
*,::after,::before{
    padding: 0;
    margin: 0;
    list-style: none;

    
}
.logo{
    height: 44px;
    min-width: 200px;
   padding: 10px;
    background: var(--light-color);
}
.logo div{
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}
.trigger{
   color: var(--dark-color);
    font-size: 1.5rem;
   
    padding: 10px 20px;
}
.ant-select-arrow span svg{
    font-size: 1rem !important;
}

::-webkit-scrollbar {
    width: 6px;
    height: 12px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ef7f1a; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #ef7f1a; 
  }

  .ant-card-bordered {
    border: 0px solid #f0f0f0;
}
.ant-radio-group {
  display: flex !important;
}
:where(.css-dev-only-do-not-override-1e3x2xa).ant-popover .ant-popover-title {
  min-width: 300px !important;
}
:where(.css-dev-only-do-not-override-1e3x2xa).ant-popover .ant-popover-content {
  width:500px !important;
}
.ant-tooltip-inner {
  background-color: var(--purple) !important;
}
.ant-tooltip-arrow:before {
  background-color: var(--purple) !important;
}
/* .ant-popover-inner {
  background: linear-gradient(90deg, #8056F7 0%, #AE92FF 100%) !important;
}
.ant-popover {
  --antd-arrow-background-color: linear-gradient(90deg, #8056F7 0%, #AE92FF 100%) !important;
} */
:where(.css-dev-only-do-not-override-1e3x2xa).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0b479d !important;
  border-color: #1868d8 !important;
}
:where(.css-dev-only-do-not-override-1e3x2xa).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #0b479d;
  background-color: #0b479d;
}
.ant-popover-inner-content {
  color: var(--light-color) !important;
}
.ant-menu-item {
  padding-left: 20px !important;
}
/* .ant-upload-select {
  border: 1px dashed #8056F7 !important;
}
.ant-btn-primary {
  background-color: #8056f7 !important;
  border-radius: 10px;
} */
:where(.css-dev-only-do-not-override-1fviqcj).ant-popover .ant-popover-inner {
  width: 450px ;
}
.ant-form-item .ant-form-item-label >label {
  position: relative;
    font-size: 14px;
    letter-spacing: 0.01em;
    font-weight: 600 !important;
    color: rgb(112 112 112);
}
.ant-input-affix-wrapper {
  font-family: Poppins, sans-serif;
  height: 40px;
  border-radius: 2px;
  box-shadow: none;
  border-color: #0b479d;
 
}
input.ant-input::placeholder{
  font-family: Poppins, sans-serif;
  font-weight: 600 !important;
    color: rgb(191 191 191);
    font-size: 13px;
}
.ant-input[disabled] {
  border-color: #0b479d;
}
 
.ant-picker {
  border: 1px solid #0b479d;
}

 th, td {
  border: 1px solid;
  padding: 0px 5px;
  /* width: 100% */
 }
 th ,td:last-child{
  border-bottom:none; 
 }
 th{
  border-top: 1px solid; 
 }
  
 th, td  {
    text-align: center;
}